// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-article-layout-index-js": () => import("./../../../src/components/ArticleLayout/index.js" /* webpackChunkName: "component---src-components-article-layout-index-js" */),
  "component---src-components-case-study-layout-index-js": () => import("./../../../src/components/CaseStudyLayout/index.js" /* webpackChunkName: "component---src-components-case-study-layout-index-js" */),
  "component---src-components-person-layout-index-js": () => import("./../../../src/components/PersonLayout/index.js" /* webpackChunkName: "component---src-components-person-layout-index-js" */),
  "component---src-routes-default-404-js": () => import("./../../../src/routes/default/404.js" /* webpackChunkName: "component---src-routes-default-404-js" */),
  "component---src-routes-default-about-us-js": () => import("./../../../src/routes/default/about-us.js" /* webpackChunkName: "component---src-routes-default-about-us-js" */),
  "component---src-routes-default-announcements-js": () => import("./../../../src/routes/default/announcements.js" /* webpackChunkName: "component---src-routes-default-announcements-js" */),
  "component---src-routes-default-case-studies-js": () => import("./../../../src/routes/default/case-studies.js" /* webpackChunkName: "component---src-routes-default-case-studies-js" */),
  "component---src-routes-default-contact-js": () => import("./../../../src/routes/default/contact.js" /* webpackChunkName: "component---src-routes-default-contact-js" */),
  "component---src-routes-default-customer-experience-js": () => import("./../../../src/routes/default/customer-experience.js" /* webpackChunkName: "component---src-routes-default-customer-experience-js" */),
  "component---src-routes-default-index-js": () => import("./../../../src/routes/default/index.js" /* webpackChunkName: "component---src-routes-default-index-js" */),
  "component---src-routes-default-perspectives-js": () => import("./../../../src/routes/default/perspectives.js" /* webpackChunkName: "component---src-routes-default-perspectives-js" */),
  "component---src-routes-default-products-js": () => import("./../../../src/routes/default/products.js" /* webpackChunkName: "component---src-routes-default-products-js" */),
  "component---src-routes-default-technology-js": () => import("./../../../src/routes/default/technology.js" /* webpackChunkName: "component---src-routes-default-technology-js" */)
}

