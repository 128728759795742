// NOTE: this file is generated automatically, any changes will be overwritten.

import Box from "@spraoi/base/Box";
import PropTypes from "prop-types";
import React from "react";
import altlogowhite from "../../images/icons/alt-logo-white.svg";
import andesalogo from "../../images/icons/andesa-logo.svg";
import barrellogo from "../../images/icons/barrel-logo.svg";
import bridge from "../../images/icons/bridge.svg";
import buoy from "../../images/icons/buoy.svg";
import certificate from "../../images/icons/certificate.svg";
import cioapplicationslogo from "../../images/icons/cio-applications-logo.svg";
import connectedworld from "../../images/icons/connected-world.svg";
import database from "../../images/icons/database.svg";
import developmentmethodology from "../../images/icons/development-methodology.svg";
import eislogo from "../../images/icons/eis-logo.svg";
import errorbot from "../../images/icons/error-bot.svg";
import facebook from "../../images/icons/facebook.svg";
import factory from "../../images/icons/factory.svg";
import gear from "../../images/icons/gear.svg";
import goldengatebridge from "../../images/icons/golden-gate-bridge.svg";
import group from "../../images/icons/group.svg";
import instagram from "../../images/icons/instagram.svg";
import insuranceciooutlooklogo from "../../images/icons/insurance-cio-outlook-logo.svg";
import insuretechconnectlogo from "../../images/icons/insuretech-connect-logo.svg";
import kclogo from "../../images/icons/kc-logo.svg";
import leftarrow from "../../images/icons/left-arrow.svg";
import lightbulb from "../../images/icons/light-bulb.svg";
import limralogo from "../../images/icons/limra-logo.svg";
import linkedin from "../../images/icons/linkedin.svg";
import logobackground from "../../images/icons/logo-background.svg";
import mailsent from "../../images/icons/mail-sent.svg";
import manufacturing from "../../images/icons/manufacturing.svg";
import observer from "../../images/icons/observer.svg";
import open from "../../images/icons/open.svg";
import penandpad from "../../images/icons/pen-and-pad.svg";
import piechart from "../../images/icons/pie-chart.svg";
import playfulcat from "../../images/icons/playful-cat.svg";
import programmer from "../../images/icons/programmer.svg";
import relaunchday from "../../images/icons/relaunch-day.svg";
import repeat from "../../images/icons/repeat.svg";
import rightarrow from "../../images/icons/right-arrow.svg";
import ruler from "../../images/icons/ruler.svg";
import scale from "../../images/icons/scale.svg";
import secure from "../../images/icons/secure.svg";
import seesaw from "../../images/icons/seesaw.svg";
import sigma from "../../images/icons/sigma.svg";
import sliders from "../../images/icons/sliders.svg";
import spesa from "../../images/icons/spe-sa.svg";
import spraoilogo from "../../images/icons/spraoi-logo.svg";
import stairs from "../../images/icons/stairs.svg";
import teamspirit from "../../images/icons/team-spirit.svg";
import thief from "../../images/icons/thief.svg";
import twitter from "../../images/icons/twitter.svg";
import westmonroelogo from "../../images/icons/west-monroe-logo.svg";
import zemfyrewhite from "../../images/icons/zemfyre-white.svg";
import zemfyre from "../../images/icons/zemfyre.svg";

const svgMap = {
  "alt-logo-white": altlogowhite,
  "andesa-logo": andesalogo,
  "barrel-logo": barrellogo,
  bridge: bridge,
  buoy: buoy,
  certificate: certificate,
  "cio-applications-logo": cioapplicationslogo,
  "connected-world": connectedworld,
  database: database,
  "development-methodology": developmentmethodology,
  "eis-logo": eislogo,
  "error-bot": errorbot,
  facebook: facebook,
  factory: factory,
  gear: gear,
  "golden-gate-bridge": goldengatebridge,
  group: group,
  instagram: instagram,
  "insurance-cio-outlook-logo": insuranceciooutlooklogo,
  "insuretech-connect-logo": insuretechconnectlogo,
  "kc-logo": kclogo,
  "left-arrow": leftarrow,
  "light-bulb": lightbulb,
  "limra-logo": limralogo,
  linkedin: linkedin,
  "logo-background": logobackground,
  "mail-sent": mailsent,
  manufacturing: manufacturing,
  observer: observer,
  open: open,
  "pen-and-pad": penandpad,
  "pie-chart": piechart,
  "playful-cat": playfulcat,
  programmer: programmer,
  "relaunch-day": relaunchday,
  repeat: repeat,
  "right-arrow": rightarrow,
  ruler: ruler,
  scale: scale,
  secure: secure,
  seesaw: seesaw,
  sigma: sigma,
  sliders: sliders,
  "spe-sa": spesa,
  "spraoi-logo": spraoilogo,
  stairs: stairs,
  "team-spirit": teamspirit,
  thief: thief,
  twitter: twitter,
  "west-monroe-logo": westmonroelogo,
  "zemfyre-white": zemfyrewhite,
  zemfyre: zemfyre,
};

const Icon = ({ svg, ...rest }) => {
  const SvgComponent = svgMap[svg];

  return (
    <Box {...rest}>
      <SvgComponent />
    </Box>
  );
};

Icon.propTypes = {
  svg: PropTypes.oneOf([
    "alt-logo-white",
    "andesa-logo",
    "barrel-logo",
    "bridge",
    "buoy",
    "certificate",
    "cio-applications-logo",
    "connected-world",
    "database",
    "development-methodology",
    "eis-logo",
    "error-bot",
    "facebook",
    "factory",
    "gear",
    "golden-gate-bridge",
    "group",
    "instagram",
    "insurance-cio-outlook-logo",
    "insuretech-connect-logo",
    "kc-logo",
    "left-arrow",
    "light-bulb",
    "limra-logo",
    "linkedin",
    "logo-background",
    "mail-sent",
    "manufacturing",
    "observer",
    "open",
    "pen-and-pad",
    "pie-chart",
    "playful-cat",
    "programmer",
    "relaunch-day",
    "repeat",
    "right-arrow",
    "ruler",
    "scale",
    "secure",
    "seesaw",
    "sigma",
    "sliders",
    "spe-sa",
    "spraoi-logo",
    "stairs",
    "team-spirit",
    "thief",
    "twitter",
    "west-monroe-logo",
    "zemfyre-white",
    "zemfyre",
  ]).isRequired,
};

export default Icon;
